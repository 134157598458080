import React from 'react';
import { connect } from 'react-redux';

import ListItemDetails from './listItemdetails';

// Actions
import { invitesActions } from '../../actions/invites';

class InviteDetailsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
    };

    // Bindings
    // this.triggerStripe = this.triggerStripe.bind(this)
    // this.stripeHandler = this.stripeHandler.bind(this)
  }

  componentDidMount() {
    const { dispatch, match } = this.props;
    if (match.params._id) dispatch(invitesActions.getInvite(match.params._id));
  }

  // triggerStripe( invite ){
  //     const clientName = invite.client ? invite.client.name : 'SimCase'
  //     this.stripeHandler(invite).open({
  //         name: clientName,
  //         description: `Game access to ${clientName}`,
  //         amount: 5 * 100
  //     })
  // }

  // stripeHandler( invite ){
  //     const { dispatch, user } = this.props;
  //     // STRIPE
  //     return window.StripeCheckout.configure({
  //         //   key: 'pk_live_pSCBeboPd01ILSaGgv2PT6fp',
  //         key: 'pk_test_5zq6R3L4K94IRoTQUfE0nCjH',
  //         image: '/images/stripe_logo.png',
  //         locale: 'auto',
  //         email: user.email,
  //         allowRememberMe: false,
  //         token: function(token) {
  //             // You can access the token ID with `token.id`.
  //             // Get the token ID to your server-side code for use.
  //             // console.log(token);
  //             fetch(process.env.REACT_APP_API_URL + '/payments/charge', {
  //                 method: 'POST',
  //                 headers: {
  //                     'Content-Type': 'application/json; charset=utf-8',
  //                 },
  //                 body: JSON.stringify({ token: token, invite: invite._id })
  //             })
  //             .then( response => response.json() )
  //             .then( res => {
  //                 console.log({ res })
  //                 dispatch(invitesActions.getInvite( invite._id ))
  //             })
  //         }
  //     });
  // }

  render() {
    const { item } = this.props;

    return (
      <div>
        {/* <h2 className="font-weight-light mt-4">
                    Invite Details 
                    <Link to="/invites" className="btn btn-sm btn-link ml-3">See all</Link>
                </h2>
                <hr/>  */}

        <div className='row mt-5'>
          {item && (
            <div
              key={`inv-${item._id}`}
              className='col-12 col-lg-8 offset-lg-2'
              style={{ height: '70vh' }}>
              <ListItemDetails item={item} />
            </div>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  const { match } = props;
  const { invites, authentication } = state;
  const { user } = authentication;
  if (match.params._id) {
    return {
      user,
      item: invites.items.find((item) => item._id === match.params._id) || null,
    };
  }
  return { user, item: null };
}

export default connect(mapStateToProps)(InviteDetailsPage);
