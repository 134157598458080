import React from 'react';
// import { Link, Redirect } from 'react-router-dom'
import { connect } from 'react-redux';

// Actions
import { schoolsActions } from '../../actions/schools';
import { clientsActions } from '../../actions/clients';

import SchoolForm from './form';

class Item extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    // Bindings
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    const { match } = this.props;
    if (match.params._id) {
      this.props.getSchool(match.params._id);
      this.props.getClients();
    }
  }

  submit(item) {
    if (item._id) {
      this.props.updateItem(item);
    } else {
      this.props.createItem(item);
    }
  }

  render() {
    const { item, clients } = this.props;

    if (!item) return <div className='alert alert-info mt-5'>Loading...</div>;

    return <SchoolForm item={item} clients={clients} onSubmit={this.submit} />;
  }
}

function mapStateToProps(state, props) {
  const { match } = props;
  const { schools, clients } = state;
  if (match.params._id) {
    return {
      item: schools.items.find((item) => item._id === match.params._id) || null,
      clients: clients.items.filter((c) => c.visibility),
    };
  }
  return { item: {} };
}

const actionCreators = {
  getSchool: schoolsActions.getSchool,
  createItem: schoolsActions.createItem,
  updateItem: schoolsActions.updateItem,
  getClients: clientsActions.getClients,
};

export default connect(mapStateToProps, actionCreators)(Item);
