import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';

// Actions
import { invitesActions } from '../../actions/invites';

class ListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: {},
      items: [],
    };

    // Bindings
    this.triggerStripe = this.triggerStripe.bind(this);
    this.stripeHandler = this.stripeHandler.bind(this);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.clearSearch = this.clearSearch.bind(this);
    this.submitSearch = this.submitSearch.bind(this);
  }

  componentDidMount() {
    const { user } = this.props;
    if (user.role === 'administrator')
      this.setState({ filters: { email: user.email } }, () =>
        this.submitSearch()
      );
    else this.submitSearch();
  }

  triggerStripe(invite) {
    const clientName = invite.client ? invite.client.name : 'SimCase';
    this.stripeHandler(invite).open({
      name: clientName,
      description: `Game access to ${clientName}`,
      amount: 5 * 100,
    });
  }

  stripeHandler(invite) {
    const { dispatch, user } = this.props;
    // STRIPE
    return window.StripeCheckout.configure({
      //   key: 'pk_live_pSCBeboPd01ILSaGgv2PT6fp',
      key: 'pk_test_5zq6R3L4K94IRoTQUfE0nCjH',
      image: '/images/stripe_logo.png',
      locale: 'auto',
      email: user.email,
      allowRememberMe: false,
      token: function (token) {
        // You can access the token ID with `token.id`.
        // Get the token ID to your server-side code for use.
        // console.log(token);
        fetch(process.env.REACT_APP_API_URL + '/payments/charge', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
          },
          body: JSON.stringify({ token: token, invite: invite._id }),
        })
          .then((response) => response.json())
          .then((res) => {
            console.log({ res });
            dispatch(invitesActions.getInvite(invite._id));
          });
      },
    });
  }

  handleFilterChange(e) {
    let filters = { ...this.state.filters };
    if (e.target.name === 'email') filters[e.target.name] = e.target.value;
    else filters[e.target.name] = e.target.value;
    this.setState({ filters });
  }

  clearSearch(e) {
    this.setState({ filters: {} });
  }

  submitSearch(e) {
    const { filters } = this.state;
    const { dispatch } = this.props;
    dispatch(invitesActions.getInvites(filters));
  }

  render() {
    const { filters } = this.state;
    const { items, user } = this.props;

    const invitesItems = items.map((i) => {
      const invitedBy = i.instructor;
      const invitedBySchool = invitedBy ? invitedBy.school : null;
      const invitedByName = invitedBy
        ? invitedBy.name +
          ' ' +
          invitedBy.lastname +
          (invitedBySchool ? ` @ ${invitedBySchool.name}` : '')
        : false;

      if (!i.client) return null;

      return (
        <div key={`inv-${i._id}`} className='card mb-4'>
          <div className='card-body'>
            <div className='row'>
              <div className='col-12 col-lg-8'>
                <img
                  src={i.client.cover_url}
                  className='mr-4 float-left'
                  alt={i.client.name}
                  style={{ width: '40px' }}
                />
                <h5 className='mb-1'>
                  {i.client.subtitle.length > 0
                    ? i.client.subtitle
                    : i.client.name}{' '}
                  {/* {i.client.name} {' '} */}
                  {/* <small>{i.client.subtitle}</small> */}
                </h5>
                <div
                  className='font-italic'
                  style={{ fontSize: '0.9rem', color: '#888' }}>
                  {invitedByName && <small>{invitedByName}. </small>}
                  <small>
                    On {moment(i.created_at).format('MM-DD-YY @ hh:mm')}
                  </small>
                </div>
              </div>

              <div className='col-12 col-lg-4 mt-3 mt-lg-0'>
                <Link
                  to={`/invites/${i._id}`}
                  className='btn btn-primary float-right stretched-link'>
                  Details
                </Link>
              </div>
            </div>
          </div>
        </div>
      );
    });

    return (
      <div>
        <div className='col-12 col-lg-6 offset-lg-3'>
          <h2 className='font-weight-light mt-5'>
            Your Invites
            {user.role === 'administrator' && (
              <div className='form-inline ml-4 float-right'>
                <div className='form-group'>
                  <button className='btn btn-link' onClick={this.clearSearch}>
                    <small>Clear</small>
                  </button>
                </div>
                <div className='form-group ml-2'>
                  <input
                    name='email'
                    className='form-control'
                    placeholder='Email'
                    value={filters.email || ''}
                    onChange={this.handleFilterChange}
                  />
                </div>
                <div className='form-group ml-2'>
                  <button
                    className='btn btn-outline-primary'
                    onClick={this.submitSearch}>
                    Filter
                  </button>
                </div>
              </div>
            )}
          </h2>
          <hr />
        </div>

        <div className='row mt-4'>
          {invitesItems.length === 0 && (
            <div className='col-12 text-center'>
              <p className='lead mt-5'>No invites found.</p>
            </div>
          )}

          <div className='col-12 col-lg-6 offset-lg-3'>{invitesItems}</div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { invites, authentication } = state;
  const { user } = authentication;
  return {
    user,
    items: invites.items,
  };
}

export default connect(mapStateToProps)(ListPage);
