import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
// import numeral from 'numeral';
// import moment from 'moment';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Actions
import { clientsActions } from '../actions/clients';

import Logo from '../assets/icon-120x120.png';
import HeaderPublic from '../components/HeaderPublic';
import Header from '../components/Header';
// import List from '../components/list'

class LibraryPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
    };

    // Bindings
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(clientsActions.getLibrary());
  }

  render() {
    const { items, loggedIn } = this.props;

    const colors = {
      negotiations: 'bg-primary',
      marketing: 'bg-success',
      management: 'bg-info',
      operations: 'bg-danger',
      math: 'bg-warning',
    };

    const clientsItems = items.map((client, ii) => {
      const name = client ? client.name : 'No name';
      const img =
        client && client.cover_url && client.cover_url.length > 0
          ? client.cover_url
          : Logo;
      const bgColor = client?.category
        ? colors[client.category]
        : 'bg-secondary';
      return (
        <div key={`inv-${client._id}`} className='col-12 col-md-6 col-lg-4'>
          <div className='card card-primary mb-4'>
            <div
              className={`card-header ${bgColor} text-white`}
              style={{ fontSize: '.8rem', padding: '7px 10px', lineHeight: 1 }}>
              <span className='float-left'>{client.duration} mins. </span>
              <span className='float-right text-capitalize'>
                {client.category}
              </span>
            </div>
            <div className='card-body text-center position-relative'>
              {client?.price === 0 ? (
                <span
                  className='badge badge-danger position-absolute'
                  style={{ top: '10px', right: '15px', fontSize: '1rem' }}>
                  FREE
                </span>
              ) : null}
              <img
                src={img}
                className='mt-4'
                style={{ width: '150px', height: '150px' }}
                alt={name}
              />
              <h4 className='mt-4 mb-4'>{name}</h4>
              <div>
                <p
                  className=''
                  dangerouslySetInnerHTML={{
                    __html: client ? client.description : '',
                  }}></p>
              </div>
              <Link
                to={'/library/' + (client.slug ? client.slug : client._id)}
                className='btn btn-md btn-outline-secondary mt-5 mb-4 stretched-link'>
                DETAILS
              </Link>
            </div>
          </div>
        </div>
      );
    });

    return (
      <div>
        {!loggedIn && <HeaderPublic />}
        {loggedIn && <Header />}

        <div className='row'>
          <div className='col-10 offset-1'>
            <h3 className='text-center mt-5 mb-5 font-weight-normal'>
              What can we help you teach your students?
            </h3>

            <hr />

            <div className='row mt-5 mb-5'>{clientsItems}</div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { clients } = state;
  const { user, loggedIn } = state.authentication;
  return {
    user,
    loggedIn,
    items: clients.items,
  };
}

export default connect(mapStateToProps)(LibraryPage);
