import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

// Actions
import { invitesActions } from '../../actions/invites';

import { Modal, ModalBody } from 'reactstrap';
import PaymentForm from '../../components/PaymentForm';
// import logoWide from '../../assets/simcase_wide.png'; // relative path to image

class ListItemDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };

    // Bindings
    this.onPayClick = this.onPayClick.bind(this);
    this.handleStripePayment = this.handleStripePayment.bind(this);
  }

  // componentDidMount(){}

  onPayClick() {
    this.setState({ modal: true });
  }

  handleStripePayment(charge) {
    const { dispatch, item } = this.props;
    fetch(process.env.REACT_APP_API_URL + '/payments/charge', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json; charset=utf-8' },
      body: JSON.stringify({ charge, invite: item._id }),
    })
      .then((response) => response.json())
      .then((res) => {
        dispatch(invitesActions.getInvite(item._id));
      });
  }

  render() {
    const { item, user } = this.props;
    const { modal } = this.state;

    const name = item.client
      ? item.client.subtitle
        ? item.client.subtitle
        : item.client.name
      : 'No name';

    // Instructor
    const invitedBy = item.instructor;
    const instructorFullname = invitedBy
      ? invitedBy.name + ' ' + invitedBy.lastname
      : '';
    // School
    const invitedBySchool = invitedBy ? invitedBy.school : null;
    const schoolName =
      invitedBySchool && invitedBySchool.name
        ? `@ ${invitedBySchool.name}`
        : '';
    const schoolSponsored =
      invitedBySchool &&
      invitedBySchool.free_to_play.indexOf(item.client._id) > -1
        ? true
        : false;
    // Invited by data
    const invitedByName = invitedBy
      ? `${instructorFullname} ${schoolName}`
      : 'your instructor';
    // Payment
    const payment = item.payment;

    // Is this a purchase? Is it completed (paid)?
    let paidPurchase =
      item.purchase &&
      item.purchase.data &&
      item.purchase.data.status === 'completed'
        ? true
        : false;
    // Wait! Is the student supposed to pay for it?
    paidPurchase =
      item.purchase && item.purchase.payer && item.purchase.payer === 'student'
        ? false
        : paidPurchase;

    // let paid_by = null;
    // let paid_on = null;
    // if (payment) {
    //   paid_by = payment.user.name + ' ' + payment.user.lastname;
    //   paid_on = payment.created_at;
    // }
    // if (schoolSponsored && invitedBySchool) {
    //   paid_by = invitedBySchool.name;
    //   paid_on = item.created_at;
    // }

    // console.log({ payment, schoolSponsored, paidPurchase })

    return (
      <div className='text-center pt-5'>
        <h4>Welcome</h4>
        <h6>{user.email}!</h6>
        <p className='lead mt-4'>
          You've been invited by {invitedByName} to play <b>{name}</b>
        </p>
        <p>
          {/* <img src={img} alt="" /> */}
          {/* PLAY */}
          {(payment || schoolSponsored || paidPurchase) && (
            <a
              href={item.data.magiclink}
              className='btn btn-lg btn-primary pl-4 pr-4 mt-4'
              target='_blank'
              rel='noopener noreferrer'>
              Play
            </a>
          )}

          {/* PAY */}
          {!payment && !schoolSponsored && !paidPurchase && (
            <button
              className='btn btn-lg btn-outline-primary pl-4 pr-4 mt-4'
              onClick={() => this.onPayClick(item)}>
              Pay
            </button>
          )}
        </p>

        <div className='row mt-5 pt-5' style={{ color: '#888' }}>
          <div className='col-12 col-lg-8 offset-lg-2'>
            <p>
              <small>Need Help?</small>
            </p>

            <div className='row mt-4'>
              {item.client.how_url.length > 0 && (
                <div className='col'>
                  <h6>How-to Video</h6>
                  <a
                    href={item.client.how_url}
                    target='_blank'
                    rel='noreferrer'>
                    <img
                      style={{ width: '50px' }}
                      src='https://hub.simcase.io/images/icon_video.png'
                      alt='How To Video'
                    />
                  </a>
                </div>
              )}

              {item.client.faq_url.length > 0 && (
                <div className='col'>
                  <h6>FAQ</h6>
                  <a
                    href={item.client.faq_url}
                    target='_blank'
                    rel='noreferrer'>
                    <img
                      style={{ width: '50px' }}
                      src='https://hub.simcase.io/images/icon_faq.png'
                      alt='FAQ'
                    />
                  </a>
                </div>
              )}

              <div className='col'>
                <h6>Chat With Us</h6>
                <a href='https://simcase.help' target='_blank' rel='noreferrer'>
                  <img
                    style={{ width: '50px' }}
                    src='https://hub.simcase.io/images/icon_email_chat.png'
                    alt='https://simcase.help'
                  />
                </a>
              </div>
            </div>

            <p className='mt-5 pt-3 text-muted'>
              <small>
                Check the list of{' '}
                <Link to='/invites' style={{ color: '#3E6BEF' }}>
                  your invites
                </Link>
                .
              </small>
            </p>

            {/* <img src={logoWide} className="logo mt-3" style={{ height: '30px', marginRight: '10px', marginTop: '-9px' }} alt="logo" /> */}
          </div>
        </div>

        <Modal
          isOpen={modal}
          toggle={() => this.setState({ modal: !modal })}
          size='lg'
          centered={true}>
          <ModalBody className='text-center'>
            <div style={{ padding: '0 5%', height: '75vh' }}>
              <div className='v-center pb-4'>
                <h6 className='text-primary font-weight-bold mt-0 mb-3'>
                  PAYMENT DETAILS
                </h6>

                <p className='lead mt-0 mb-5 ml-5 mr-5'>
                  {!payment && (
                    <span>
                      We'll use{' '}
                      <a
                        href='https://stripe.com'
                        target='_blank'
                        rel='noopener noreferrer'>
                        Stripe
                      </a>{' '}
                      as a secure, third-party payment platform.
                    </span>
                  )}
                  <br />
                  {!payment && (
                    <small className='font-italic font-weight-light'>
                      Pay for your license to then access your game.
                    </small>
                  )}

                  {payment && (
                    <span>
                      Your payment was successful. You can now access the game.
                    </span>
                  )}
                  <br />
                  {payment && (
                    <small className='font-italic font-weight-light'>
                      Click the "Play" button below or return to your invitation
                      list and play later.
                    </small>
                  )}

                  {payment && (
                    <a
                      href={item.data.magiclink}
                      className='btn btn-block btn-md btn-primary mt-5'
                      style={{ width: '200px', margin: '0 auto' }}
                      target='_blank'
                      rel='noopener noreferrer'>
                      Play
                    </a>
                  )}
                </p>

                {!payment && (
                  <PaymentForm
                    client={item.client._id}
                    quantity={1}
                    fields={['name', 'email']}
                    values={{
                      name: `${user.name} ${user.lastname}`,
                      email: user.email,
                    }}
                    onSuccess={this.handleStripePayment}
                  />
                )}

                <small className='form-text text-muted mt-3'>
                  By confirming payment you are acknowledging that you accept
                  our{' '}
                  <a
                    href='https://simcase.io/terms-and-conditions'
                    target='_blank'
                    rel='noopener noreferrer'>
                    Terms &amp; Conditions
                  </a>
                  .
                </small>

                {/* {payment && <div>
                                <p className="lead mt-0 mb-5 ml-5 mr-5">
                                    You can now access your session by clicking the "Play" button in the invite.
                                </p>
                                <button className="btn btn-outline-primary" onClick={() => this.setState({ modal: false })}>Close</button>
                            </div> } */}

                {/* <button className="btn btn-sm btn-link mt-3" onClick={() => this.setState({ modal: false })}>Cancel</button> */}
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state, props) {
  const { user } = state.authentication;
  return { user };
}

export default connect(mapStateToProps)(ListItemDetails);
