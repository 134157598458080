import React from 'react';

class ListFilters extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: props?.filters || {},
    };
    // Bindings
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.clearForm = this.clearForm.bind(this);
  }

  // componentDidMount() {}

  handleFilterChange(e) {
    let filters = { ...this.state.filters };
    filters[e.target.name] = e.target.value;
    this.setState({ filters });
  }

  clearForm() {
    this.setState({ filters: {} });
  }

  render() {
    const { filters } = this.state;
    const { onSubmit, fields } = this.props;

    const formFields = fields.map((f, fi) => {
      const fval = filters[f.name] || '';
      return (
        <div key={`filter-${fi}`} className='form-group ml-2'>
          <input
            name={f.name}
            className='form-control'
            placeholder={f.placeholder || ''}
            value={fval}
            onChange={this.handleFilterChange}
          />
        </div>
      );
    });

    return (
      <div className='form-inline'>
        <div className='form-group'>
          <button className='btn btn-link' onClick={this.clearForm}>
            <small>Clear</small>
          </button>
        </div>

        {formFields}

        {onSubmit && (
          <div className='form-group ml-2'>
            <button
              className='btn btn-outline-primary'
              onClick={(e) => onSubmit(filters)}>
              Filter
            </button>
          </div>
        )}
      </div>
    );
  }
}
export default ListFilters;
