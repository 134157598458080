import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Modal, ModalBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Actions
import { purchasesActions } from '../actions/purchases';

class TrialButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      modal_trial: false,
      trial: null,
    };

    // Bindings
    this.createTrial = this.createTrial.bind(this);
  }

  componentDidMount() {
    const { dispatch, user, item } = this.props;
    if (user && user._id && item)
      dispatch(
        purchasesActions.getPurchases({
          'metadata[trial]': true,
          client: item._id,
          customer: user._id,
        })
      );
  }

  createTrial() {
    const { dispatch, item, user } = this.props;
    dispatch(
      purchasesActions.create(
        item,
        user.email,
        'instructor',
        'now',
        10,
        { trial: true },
        (result) => {
          this.setState({ trial: result });
        }
      )
    );
  }

  render() {
    const { modal_trial } = this.state;
    const { item, trials, loading } = this.props;
    const loginLink =
      item.direct_url && item.direct_url.length > 0
        ? item.direct_url
        : `/oauth/authorize?client_id=${item.client_id}&response_type=code&redirect_uri=${item.redirect_url}&scope=student`;

    const trial = trials.length > 0 ? true : false;

    return (
      <div>
        {!loading && !trial && (
          <button
            onClick={() => this.setState({ modal_trial: true })}
            className='btn btn-block btn-link'>
            Free Trial
          </button>
        )}

        {!loading && trial && (
          <Link to='/contact' className='btn btn-block btn-link'>
            Contact Us
          </Link>
        )}

        <Modal
          isOpen={modal_trial}
          toggle={() => this.setState({ modal_trial: !modal_trial })}
          size='lg'
          centered={true}>
          <ModalBody className='text-center'>
            <div style={{ padding: '0 5%' }}>
              <div
                className='mb-5'
                style={{ height: '60vh', margin: '0 15px' }}>
                <div className='text-center v-center'>
                  <h6 className='text-primary font-weight-bold mt-0 mb-3 text-uppercase'>
                    FREE TRIAL DETAILS
                  </h6>

                  {!trial && (
                    <div>
                      <p className='lead mt-0'>
                        Explore how SimCase easily adds engagement.
                      </p>
                      <p className='lead mt-0 mb-5'>
                        You can set up <b>one free session</b>
                        <br />
                        with up to <b>10 participants</b>.
                      </p>
                      <p className='mt-0 mb-0'>
                        <button
                          className='btn btn-outline-primary btn-lg mb-3'
                          onClick={this.createTrial}>
                          Launch Trial
                        </button>
                        <br />
                        <small className='form-text text-muted'>
                          By launching a trial game session you are
                          acknowledging that you accept our{' '}
                          <a
                            href='https://simcase.io/terms-and-conditions'
                            target='_blank'
                            rel='noopener noreferrer'>
                            Terms &amp; Conditions
                          </a>
                          .
                        </small>
                      </p>
                    </div>
                  )}

                  {trial && (
                    <div>
                      <FontAwesomeIcon
                        icon='check'
                        size='4x'
                        className='icon mt-5 mb-3 text-success'
                      />
                      <p className='lead mt-0'>
                        Your free trial setup was successful!
                      </p>
                      <p className='lead mt-0 mb-5'>
                        Check your email to access your game session or access
                        the game lobby below.
                      </p>
                      <div className='mt-5'>
                        <a
                          href={loginLink}
                          target='_blank'
                          rel='noopener noreferrer'
                          className='btn btn-outline-primary mr-5'>
                          Go to Game
                        </a>
                        <button
                          className='btn btn-outline-primary ml-5'
                          onClick={() => this.setState({ modal_trial: false })}>
                          Return To Library
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { purchases } = state;
  return {
    trials: purchases.items,
    loading: purchases.loading,
  };
}

export default connect(mapStateToProps)(TrialButton);
