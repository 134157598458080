import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';
// import numeral from 'numeral';
import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Actions
import { clientsActions } from '../../actions/clients';

import List from '../../components/list';
import ListFilters from '../../components/ListFilters';

class ListPage extends React.Component {
  constructor(props) {
    super(props);

    let qs = queryString.parse(window.location.search);

    this.state = {
      items: [],
      qs: { status: 'created', ...qs },
    };

    // Bindings
    this.deleteItem = this.deleteItem.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
  }

  componentDidMount() {
    const { dispatch } = this.props;
    const { qs } = this.state;
    dispatch(clientsActions.getClients({ ...qs }));
  }

  // Methods
  deleteItem(item) {
    const { dispatch } = this.props;
    const toDelete = { ...item };
    toDelete.status = 'deleted';
    if (item._id) {
      dispatch(clientsActions.updateItem(toDelete));
    }
  }

  handleSearch(filters) {
    this.setState({ qs: { ...this.state.qs, ...filters } }, () => {
      const { qs } = this.state;
      const { dispatch } = this.props;
      dispatch(clientsActions.getClients({ ...qs }));
    });
  }

  render() {
    const { items } = this.props;

    const clientsItems = items.map((i, ii) => {
      return {
        name: (
          <Link
            key={`link-edit-${ii}`}
            className={i?.status === 'deleted' ? 'text-secondary' : ''}
            to={'/clients/' + i._id}>
            {i.name}
          </Link>
        ),
        subtitle: (
          <Link key={`link-edit-${ii}`} to={'/clients/' + i._id}>
            {i.subtitle}
          </Link>
        ),
        code: i.public_code,
        // redirect_url: i.redirect_url,
        client_id: (
          <small>
            <code>{i.client_id}</code>
          </small>
        ),
        client_secret: (
          <small>
            <code>{i.client_secret}</code>
          </small>
        ),
        // cover_url: i.cover_url,
        status: i.status,
        visibility: i.visibility.toString(),
        order: i.order,
        created_at: (
          <small>{moment(i.created_at).format('MMM Do YYYY, h:mm a')}</small>
        ),
        actions: [
          <Link
            key={`btn-edit-${ii}`}
            to={'/clients/' + i._id}
            className='btn btn-sm btn-outline-primary'>
            Edit
          </Link>,
          <button
            key={`btn-del-${ii}`}
            onClick={(e) => this.deleteItem(i)}
            disabled={i.status === 'deleted' ? true : false}
            className={`btn btn-sm btn-light ml-3 ${
              i.status === 'deleted' ? 'disabled text-secondary' : 'text-danger'
            }`}>
            <FontAwesomeIcon
              icon='trash-alt'
              size='1x'
              style={{ cursor: 'pointer' }}
            />
          </button>,
        ],
        // Specific classes for this row
        rowClass: i.status === 'deleted' ? 'text-muted bg-light disabled' : '',
      };
    });

    return (
      <div>
        <h2 className='font-weight-light mt-4'>
          Clients
          <Link to='/clients/new' className='btn btn-outline-primary ml-3'>
            Add new
          </Link>
          <div className='float-right'>
            <ListFilters
              onSubmit={this.handleSearch}
              fields={[{ name: 'status', placeholder: 'Status' }]}
              filters={this.state.qs}
            />
          </div>
        </h2>
        {/* <hr/>  */}

        <div className='mt-3'>
          <List
            labels={[
              '#',
              'Name',
              'Subtitle',
              'Code',
              'Client_Id',
              'Secret',
              'Visible',
              'Status',
              'Created_At',
              'Actions',
            ]}
            fields={[
              'order',
              'name',
              'subtitle',
              'code',
              'client_id',
              'client_secret',
              'visibility',
              'status',
              'created_at',
              'actions',
            ]}
            data={clientsItems}
            size='table-sm'
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { clients } = state;
  return {
    items: clients.items,
  };
}

export default connect(mapStateToProps)(ListPage);
