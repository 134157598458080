import { userActions } from './';
import { authHeader, handleJSONResponse, store, history } from '../helpers';

// Inner Helpers
const checkJSONRequest = (response) => {
  const dispatch = store.dispatch;
  return handleJSONResponse(response, (data, code) => {
    if (code === 401) dispatch(userActions.logout());
    if (code !== 401) history.push('/error?code=403');
  });
};

export const INVITES_FETCHONE_SUCCESS = 'INVITES_FETCHONE_SUCCESS';
export const INVITES_GETALL_REQUEST = 'INVITES_GETALL_REQUEST';
export const INVITES_GETALL_SUCCESS = 'INVITES_GETALL_SUCCESS';
export const INVITES_GETALL_FAILURE = 'INVITES_GETALL_FAILURE';

export const invitesActions = {
  getInvite: fetchOne,
  // updateItem,
  getInvites: getAll,
  // delete: _delete
};

function fetchOne(id) {
  return (dispatch) => {
    fetch(`/api/invites/${id}`, {
      method: 'GET',
      headers: authHeader(),
    })
      .then(checkJSONRequest)
      .then((data) => dispatch(success(data[0] || null)));
  };
  // function request() { return { type: usersConstants.GETALL_REQUEST } }
  function success(item) {
    return { type: INVITES_FETCHONE_SUCCESS, item };
  }
  // function failure(error) { return { type: usersConstants.GETALL_FAILURE, error } }
}

// function updateItem(item) {
//     return dispatch => {
//         fetch(`/api/users/${item._id}`, {
//             method: 'POST',
//             headers: {'Content-Type': 'application/json', ...authHeader()},
//             body: JSON.stringify(item)
//         })
//         .then( response => response.json() )
//         .then( data => dispatch(success( data || null )) );
//     }
//     // function request() { return { type: usersConstants.GETALL_REQUEST } }
//     function success(user) { return { type: usersConstants.UPDATEONE_SUCCESS, item: user } }
//     // function failure(error) { return { type: usersConstants.GETALL_FAILURE, error } }
// }

function getAll(filters = {}) {
  return (dispatch) => {
    dispatch(request());

    const qstring = Object.keys(filters)
      .filter((key) => filters[key].length > 0)
      .map((key) => `${key}=${filters[key]}`)
      .join('&');

    fetch(
      `${process.env.REACT_APP_API_URL}/invites/all${
        qstring ? '?' + qstring : ''
      }`,
      {
        method: 'GET',
        headers: authHeader(),
      }
    )
      .then(checkJSONRequest)
      .then(
        (items) => dispatch(success(items)),
        (error) => dispatch(failure(error))
      );
  };

  function request() {
    return { type: INVITES_GETALL_REQUEST };
  }
  function success(items) {
    return { type: INVITES_GETALL_SUCCESS, items };
  }
  function failure(error) {
    return { type: INVITES_GETALL_FAILURE, error };
  }
}

// // prefixed function name with underscore because delete is a reserved word in javascript
// function _delete(id) {
//     return dispatch => {

//         dispatch(request(id));

//             fetch(process.env.REACT_APP_API_URL + `/users/${id}`, {
//                 method: 'DELETE',
//                 headers: authHeader()
//             })
//             .then(
//                 user => {
//                     dispatch(success(id));
//                 },
//                 error => {
//                     dispatch(failure(id, error));
//                 }
//             );
//     };

//     function request(id) { return { type: usersConstants.DELETE_REQUEST, id } }
//     function success(id) { return { type: usersConstants.DELETE_SUCCESS, id } }
//     function failure(id, error) { return { type: usersConstants.DELETE_FAILURE, id, error } }
// }
