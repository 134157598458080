import {
  INVITES_FETCHONE_SUCCESS,
  INVITES_GETALL_SUCCESS,
  INVITES_GETALL_FAILURE,
} from '../actions/invites';

export function invites(state = { items: [] }, action) {
  const { items } = state;
  switch (action.type) {
    case INVITES_FETCHONE_SUCCESS:
      const index = items.findIndex((i) => i._id === action.item._id);
      return {
        ...state,
        items:
          index > -1
            ? items.map((item) =>
                item._id === action.item._id ? action.item : item
              )
            : [...items, action.item],
      };
    // case usersConstants.UPDATEONE_SUCCESS:
    //   return {
    //     ...state,
    //     items: items.map( item => item._id === action.item._id ? action.item : item )
    //   };
    // case usersConstants.GETALL_REQUEST:
    //   return {
    //     ...state,
    //     loading: true
    //   };
    case INVITES_GETALL_SUCCESS:
      return {
        ...state,
        items: action.items,
      };
    case INVITES_GETALL_FAILURE:
      return {
        ...state,
        error: action.error,
      };
    // case usersConstants.DELETE_REQUEST:
    //   // add 'deleting:true' property to user being deleted
    //   return {
    //     ...state,
    //     items: state.items.map(user =>
    //       user.id === action.id
    //         ? { ...user, deleting: true }
    //         : user
    //     )
    //   };
    // case usersConstants.DELETE_SUCCESS:
    //   // remove deleted user from state
    //   return {
    //     ...state,
    //     items: state.items.filter(user => user.id !== action.id)
    //   };
    // case usersConstants.DELETE_FAILURE:
    //   // remove 'deleting:true' property and add 'deleteError:[error]' property to user
    //   return {
    //     ...state,
    //     items: state.items.map(user => {
    //       if (user.id === action.id) {
    //         // make copy of user without 'deleting:true' property
    //         const { deleting, ...userCopy } = user;
    //         // return copy of user with 'deleteError:[error]' property
    //         return { ...userCopy, deleteError: action.error };
    //       }

    //       return user;
    //     })
    //   };
    default:
      return state;
  }
}
