import React from 'react';
// import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
// import { Modal, ModalBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Actions
import { purchasesActions } from '../actions/purchases';

class FreeWizard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      modal_trial: false,
      quantity: '',
      purchase: null,
    };

    // Bindings
    this.handleChange = this.handleChange.bind(this);
    this.finish = this.finish.bind(this);
    this.generatePurchase = this.generatePurchase.bind(this);
  }

  // componentDidMount() {
  //     const { user } = this.props
  // }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  generatePurchase() {
    const { dispatch, item, user } = this.props;

    dispatch(
      purchasesActions.create(
        item,
        user.email,
        'instructor',
        'now',
        999,
        { free: true },
        (result) => {
          this.setState({ purchase: result });
        }
      )
    );
  }

  finish() {
    if (typeof this.props.onFinish !== 'undefined') this.props.onFinish();
    else console.log('Finish');
  }

  render() {
    const { purchase } = this.state;
    const { item } = this.props;
    const loginLink =
      item.direct_url && item.direct_url.length > 0
        ? item.direct_url + `?pid=${purchase?.data?.order}`
        : `/oauth/authorize?client_id=${item.client_id}&response_type=code&redirect_uri=${item.redirect_url}&scope=student`;

    return (
      <div>
        <div className='pl-3 pr-3'>
          <div style={{ height: '65vh', margin: '0 15px' }}>
            <div className='text-center v-center'>
              <h6
                className='text-primary font-weight-bold mt-0 mb-5'
                style={{ lineHeight: '1.7rem' }}>
                {purchase ? (
                  'PURCHASE COMPLETED'
                ) : (
                  <>
                    {item.name}
                    <br />
                    FREE ACCESS
                  </>
                )}
              </h6>

              {!purchase && (
                <div>
                  <p className='lead mt-0'>
                    Your free game session is one click away.
                  </p>
                  <p className='lead mt-0 mb-3 mb-lg-5'>
                    Need help? Email us at{' '}
                    <a
                      href='mailto:hello@simcase.io'
                      target='_blank'
                      rel='noopener noreferrer'>
                      hello@simcase.io
                    </a>{' '}
                    or use the chat icon below.
                  </p>
                  <p className='mt-5 mb-0'>
                    <button
                      className='btn btn-outline-primary btn-lg mb-5'
                      onClick={this.generatePurchase}>
                      Launch Free Session
                    </button>
                    <br />
                    <small className='form-text text-muted'>
                      By launching a game session you are acknowledging that you
                      accept our{' '}
                      <a
                        href='https://simcase.io/terms-and-conditions'
                        target='_blank'
                        rel='noopener noreferrer'>
                        Terms &amp; Conditions
                      </a>
                      .
                    </small>
                  </p>
                </div>
              )}

              {purchase && (
                <div>
                  <FontAwesomeIcon
                    icon='check'
                    size='4x'
                    className='icon mt-4 mb-3 text-success'
                  />
                  <p className='lead mt-0'>Your game setup was successful.</p>
                  <p className='lead mt-0 mb-3 mb-lg-5'>
                    Check your email to access your game session
                    <br />
                    or access the game lobby below.
                  </p>
                  <div className='text-center mt-5'>
                    <a
                      href={loginLink}
                      target='_blank'
                      rel='noopener noreferrer'
                      className='btn btn-outline-primary btn-lg mb-3'>
                      Go to Game
                    </a>
                    <br />
                    <button
                      className='btn btn-link text-secondary'
                      onClick={this.finish}>
                      Close
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const { item } = ownProps;
  const { user } = state.authentication;
  return { user, item };
}

export default connect(mapStateToProps)(FreeWizard);
