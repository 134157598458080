import React from 'react';
import { connect } from 'react-redux';

// Actions
import { purchasesActions } from '../../actions/purchases';

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: this.props.item || null,
      status: 'init',
      changed: false,
    };

    // Bindings
    this.handleChange = this.handleChange.bind(this);
    this.sync = this.sync.bind(this);
    this.submit = this.submit.bind(this);
  }

  handleChange(e) {
    let item = { ...this.state.item };
    item[e.target.name] = e.target.value;
    item['changed'] = true;
    this.setState({ item });
  }

  sync(e) {
    console.log('sync...');
  }

  submit(e) {
    e.preventDefault();
    const { item } = this.state;
    if (this.props.onSubmit) this.props.onSubmit(item);
  }

  render() {
    const { item } = this.state;

    if (!item) return <h2>Loading...</h2>;

    return (
      <form onSubmit={this.submit}>
        <div className='row'>
          <h2 className='col-12 sec-title' style={{ lineHeight: 1.3 }}>
            Purchase: {item._id || ''}{' '}
            <div className='float-right' style={{ lineHeight: 1 }}>
              <button className='btn btn-md btn-outline-primary' type='submit'>
                Save
              </button>
            </div>
          </h2>
        </div>

        <div className='row mt-4'>
          <div className='col-6'>
            <div className='form-group'>
              <label>Order</label>
              <div className='input-group'>
                <input
                  name='order'
                  className='form-control'
                  placeholder='Order'
                  value={item.data.order || ''}
                  //   onChange={this.handleChange}
                  disabled={true}
                />
              </div>
            </div>
            <div className='form-group'>
              <label>Platform</label>
              <div className='input-group'>
                <input
                  name='platform'
                  className='form-control'
                  placeholder='Lastname'
                  value={item.data.platform || ''}
                  //   onChange={this.handleChange}
                  disabled={true}
                />
              </div>
            </div>
            <div className='form-group'>
              <label>Client</label>
              <div className='input-group'>
                <input
                  name='client'
                  className='form-control'
                  placeholder='Order'
                  value={item.client.name || ''}
                  //   onChange={this.handleChange}
                  disabled={true}
                />
              </div>
            </div>
            <div className='form-group'>
              <label>Client Code</label>
              <div className='input-group'>
                <input
                  name='client'
                  className='form-control'
                  placeholder='Order'
                  value={item.client.public_code || ''}
                  //   onChange={this.handleChange}
                  disabled={true}
                />
              </div>
            </div>
            <div className='form-group'>
              <label>Session</label>
              <div className='input-group'>
                <input
                  name='session'
                  className='form-control'
                  placeholder='Session'
                  value={
                    item.session
                      ? `${item.client.client_root}/admin/games/${item.session}`
                      : ''
                  }
                  //   onChange={this.handleChange}
                  disabled={true}
                />
              </div>
              {this.session ? (
                <button
                  className='btn btn-sm btn-warning mt-2'
                  onClick={this.sync}>
                  Sync data from session
                </button>
              ) : null}
            </div>
          </div>

          <div className='col-6'>
            <div className='form-group'>
              <label>Customer (Email/Id)</label>
              <div className='input-group'>
                <input
                  name='customer'
                  className='form-control'
                  placeholder='Order'
                  value={item.customer.email || ''}
                  //   onChange={this.handleChange}
                  disabled={true}
                />
              </div>
            </div>
            <div className='form-group'>
              <label>Customer Name</label>
              <div className='input-group'>
                <input
                  name='customer'
                  className='form-control'
                  placeholder='Customer Name'
                  value={
                    item.customer.name + ' ' + item.customer.lastname || ''
                  }
                  //   onChange={this.handleChange}
                  disabled={true}
                />
              </div>
            </div>

            <div className='form-group'>
              <label>Status</label>
              <div className='input-group'>
                <input
                  name='status'
                  className='form-control'
                  placeholder='Status'
                  value={item.data.status || ''}
                  //   onChange={this.handleChange}
                  disabled={true}
                />
              </div>
            </div>

            <div className='form-group'>
              <label>Created At</label>
              <div className='input-group'>
                <input
                  name='created_at'
                  className='form-control'
                  placeholder='Created At'
                  value={item.created_at || ''}
                  //   onChange={this.handleChange}
                  disabled={true}
                />
              </div>
            </div>

            <div className='form-group'>
              <label>Updated At</label>
              <div className='input-group'>
                <input
                  name='updated_at'
                  className='form-control'
                  placeholder='Updated At'
                  value={item.updated_at || ''}
                  //   onChange={this.handleChange}
                  disabled={true}
                />
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

function mapStateToProps(state, props) {
  return {};
}

const actionCreators = { ...purchasesActions };

export default connect(mapStateToProps, actionCreators)(Form);
