import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
// import numeral from 'numeral';
// import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Actions
import { schoolsActions } from '../../actions/schools';

import List from '../../components/list';

class ListPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
    };

    // Bindings
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(schoolsActions.getSchools());
  }

  render() {
    const { items } = this.props;

    const schoolItems = items.map((i, ii) => {
      return {
        name: (
          <Link key={`btn-edit-${ii}`} to={'/schools/' + i._id}>
            {i.name}
          </Link>
        ),
        code: i.code,
        email: i.email,
        port_access: i.port_access,
        free_to_play: i.free_to_play.length,
        created_at: i.created_at,
        actions: [
          <Link
            key={`btn-edit-${ii}`}
            to={'/schools/' + i._id}
            className='btn btn-sm btn-outline-success'>
            Edit
          </Link>,
          <Link
            key={`btn-del-${ii}`}
            to={'/schools/delete/' + i._id}
            className='btn btn-sm btn-light text-danger ml-3'>
            <FontAwesomeIcon
              icon='trash-alt'
              size='1x'
              style={{ cursor: 'pointer' }}
            />
          </Link>,
        ],
      };
    });

    return (
      <div>
        <h2 className='font-weight-light mt-4'>
          Schools
          <div className='float-right'>
            <Link to='/schools/new' className='btn btn-outline-primary'>
              Add new
            </Link>
          </div>
        </h2>
        {/* <hr/>  */}

        <div className='mt-3'>
          <List
            labels={[
              'Name',
              'Code',
              'Email',
              'Access',
              'FreeToPlay',
              'Created At',
              'Actions',
            ]}
            fields={[
              'name',
              'code',
              'email',
              'port_access',
              'free_to_play',
              'created_at',
              'actions',
            ]}
            data={schoolItems}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { schools } = state;
  return {
    items: schools.items,
  };
}

export default connect(mapStateToProps)(ListPage);
